import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ColorBoxCSS from "./product-colorbox.module.scss"

import ColorLabels from "../../../jsons/labels.json"

const ColorBox = props => (
  <StaticQuery
    query={graphql`
      query {
        colors: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      let toneNr =  props.filename.match(/\d+/)[0];
      let toneClass = props.filename.replace(/[0-9]/g, '');

      if(toneClass.includes("amonn")) toneClass = "amonn";
      else toneClass = "oli";

      const image = data.colors.edges.find(n => {

        var filename = props.filename;
        if(filename === "") filename = "empty.png";
        var colorFile = n.node.relativePath.includes(filename)
        if(colorFile !== undefined){
          return n.node.relativePath.includes(filename)
        } else {
          return n.node.relativePath.includes("empty.png")
        }
      })
      if (!image) {
        return null
      }

        

      return(
        <div  className={ColorBoxCSS.box}>
          <Img className={ColorBoxCSS.image} alt="" fluid={image.node.childImageSharp.fluid} />
          <span className={ColorBoxCSS.label}>{ColorLabels[""+toneClass][""+toneNr]}</span>
        </div>
      )
    }}
  />
)

export default ColorBox;
