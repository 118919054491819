import React from "react"

import IconBox from "./product-iconbox"
import ColorBox from "./product-colorbox"

const IconBoxes = props => {

  const boxes = [];

  for(var i=0; i<props.filenames.length; i++){
    if(props.type === "icon") boxes.push(<IconBox filename={props.filenames[i]} />);
    if(props.type === "color") boxes.push(<ColorBox filename={props.filenames[i]} />);
  }

  return(
    <div className="flex flex-wrap justify-left">
      {boxes}
    </div>
  )

}

export default IconBoxes
