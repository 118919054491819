import React from "react"

import ProductContentCSS from "./product-content.module.scss"

const ProductContent = props => (
  <h5 className={ProductContentCSS.header}>
    {props.header}
    <br />
    <br />
    <span className={ProductContentCSS.text}>{props.children}</span>
  </h5>
)

export default ProductContent
