import React from 'react';

import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import IconBoxCSS from "./product-iconbox.module.scss"

const IconBox = props => (
  <StaticQuery
    query={graphql`
      query {
        icons: allFile {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 200) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.icons.edges.find(n => {
        var filename = props.filename;
        if(filename === "") filename = "empty.png";
        var iconFile = n.node.relativePath.includes(filename)
        if(iconFile !== undefined){
          return n.node.relativePath.includes(filename)
        } else {
          return n.node.relativePath.includes("empty.png")
        }
      })
      if (!image) {
        return null
      }
      return(
        <Img alt="" fluid={image.node.childImageSharp.fluid} className={IconBoxCSS.box} />
      )
    }}
  />
)

export default IconBox;
