import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import MobileBackbutton from "../components/mobile-backbutton"
import ProductHeader from "../components/productpage/product-header"
import ProductContent from "../components/productpage/product-content"
import ProductContentContainerCSS from "../components/productpage/product-content-container.module.scss"
import IconBoxes from "../components/productpage/product-iconboxes"

const ProductPage = ({ data }) => {
  const content = data.dataJson
  return (
    <Layout>
      <SEO title={content.title} />
      <MobileBackbutton />
      <ProductHeader name={content.title} desc={content.desc} thumbnail={content.thumbnail} background={content.background} brand={content.brand}/>

      <div className="block pv4"></div>


      <div className="flex flex-wrap justify-center mb4-ns">
        <div className="w-100 w-40-ns flex justify-center pr5-ns">

          <div className="flex flex-column">
            <div className="mb4">
              <ProductContent header={content.header1}>
                <div dangerouslySetInnerHTML={{__html: content.text1}} />
              </ProductContent>
            </div>

            <div className="mb4"><ProductContent header={content.header3}>
              <div dangerouslySetInnerHTML={{__html: content.text3}} />
            </ProductContent></div>

            <div className="mb4"><ProductContent header={content.header7}>
              <IconBoxes type="color" filenames={content.colorFilenames} />
              <div dangerouslySetInnerHTML={{__html: content.text7}} />
            </ProductContent></div>
            <div className="mb4"><ProductContent header="Uzklāšana">
              <IconBoxes type="icon" filenames={content.useicons} />
            </ProductContent></div>


            <div className="mb4">
            <ProductContent header={content.header8}>
            <div dangerouslySetInnerHTML={{__html: content.text8}} />
          </ProductContent>
            </div>
          </div>


        </div>
        <div className="w-100 w-40-ns flex justify-center">

          <div className="flex flex-column">
            <div className="mb4">
              <ProductContent header={content.header2}>
                <div dangerouslySetInnerHTML={{__html: content.text2}} />
              </ProductContent>
            </div>

            <div className="mb4"><ProductContent header={content.header4}>
              <div dangerouslySetInnerHTML={{__html: content.text4}} />
            </ProductContent></div>

            <div className="mb4"><ProductContent header={content.header6}>
              <IconBoxes type="icon" filenames={content.applyFilenames} />
              <div dangerouslySetInnerHTML={{__html: content.text6}} />
            </ProductContent></div>

            <div className="mb4"><ProductContent header={content.header5}>
              <div dangerouslySetInnerHTML={{__html: content.text5}} />
            </ProductContent></div>
          </div>


        </div>
      </div>


      <div style={{height:"8vh"}}></div>
    </Layout>
  )
}

export default ProductPage

export const query = graphql`
  query($slug: String!) {
    dataJson(fields: { slug: { eq: $slug } }) {
      title
      desc
      usetitles
      useicons
      header1
      text1
      header2
      text2
      header3
      text3
      header4
      text4
      header5
      text5
      header6
      text6
      header7
      text7
      header8
      text8
      applyFilenames
      colorFilenames
      thumbnail
      background
      brand
    }
  }
`
