import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import ProductHeaderCSS from "./product-header.module.scss"
import DynamicImage from "../dynamic-image"

const Image = props => {
  const data = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "backgrounds/amonn/4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      fg: file(relativePath: { eq: "products/amonn/aquagel.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 308) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div>
      <DynamicImage filename={"backgrounds/" + props.brand + "/" + props.background} class={ProductHeaderCSS.bg} />
      <DynamicImage filename={"products/" + props.brand + "/" + props.thumbnail} class={ProductHeaderCSS.fg} />
      <h3 className={ProductHeaderCSS.productName}>{props.name}</h3>
      <div className={ProductHeaderCSS.divider}></div>
      <p className={ProductHeaderCSS.productDesc}>{props.desc}</p>
    </div>
  )
}

export default Image
